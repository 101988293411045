<template>
  <div class="RegisterForMeeting">
    <button class="register-button" type="button" @click="registerMeetingWithDetails">
      <PlainRichTextToggle
        :rich="false"
        _key="register_text"
        placeholder="Register"
        :text="registerText"
        v-bind="{ form, userData }"
        @update-form="$emit('update-form', $event)"
        @update="$emit('update', $event)"
      >
        <div class="register-text">
          {{ registerText | computed(userData, form) }}
        </div>
      </PlainRichTextToggle>
    </button>
  </div>
</template>
<script>
import api from '@/helpers/flowApi'
import computedValues from './editor/helpers/computedValues'
import notify from '@/helpers/notifyLite'
import { unpack } from '@/helpers/computed'

import PlainRichTextToggle from './PlainRichTextToggle'

export default {
  name: 'RegisterForMeeting',
  components: { PlainRichTextToggle },
  props: {
    component: Object,
    userData: Object,
    form: Object,
  },
  computed: {
    ...unpack('component', ['source']),
    registerText() {
      return (this.component && this.component.register_text) || 'Register'
    },
    componentKey() {
      return this.component.key || this.component.id
    },
    userDataValue: {
      get() {
        return this.userData && this.userData[this.componentKey]
      },
      set(v) {
        this.$emit('update', [this.componentKey, v])
      },
    },
  },
  watch: {},
  methods: {
    async registerForMeeting() {
      this.inProgress = true
      try {
        switch (this.source) {
          case 'zoom': {
            await this.registerMeetingWithDetails()
            break
          }

          default:
            break
        }
      } catch (error) {
        console.error(error)
        await notify(`registerForMeeting`, error, { form: this.form, userData: this.userData })
      }
      this.inProgress = false
    },
    async registerMeetingWithDetails() {
      try {
        const details = {
          email: computedValues(this.userData, this.component.email_key, this.form),
          name: computedValues(this.userData, this.component.name_key, this.form),
          address: computedValues(this.userData, this.component.address_key, this.form),
          city: computedValues(this.userData, this.component.city_key, this.form),
          country: computedValues(this.userData, this.component.country_key, this.form),
          zip: computedValues(this.userData, this.component.zip_key, this.form),
          state: computedValues(this.userData, this.component.state_key, this.form),
          phone: computedValues(this.userData, this.component.phone_key, this.form),
        }
        const data = { details, flowId: this.form.id, meetingId: this.component.meeting_id }

        const res = await api('/public/flows/meeting-register', data)

        /* If an error occurred and res.success is false, the backend will notify */
        if (res.success) {
          this.userDataValue = res
        }
      } catch (error) {
        console.error(error)
        await notify(`Registering for Meeting with details`, error, {
          form: this.form,
          userData: this.userData,
        })
      }
    },
  },
}
</script>
